import { Link } from "react-router-dom";

export default function LoginSuccess() {
    return (
        <div>
            <Link to="/bookkeeping/general">
                <button className="loginButton">
                    <em>B</em>ook<em>k</em>eeping
                </button>
            </Link>
        </div>
    );
  }