import { useState, useEffect } from 'react';
import { requestMakeChanges } from './fetchData';
import { useMsal } from '@azure/msal-react';

export default function CantDoWarning({largeScreen, canMakeChanges, setCanMakeChanges}) {
    const { instance } = useMsal();
    const [ isDragging, setDragging ] = useState(false);
    const [ offset, setOffset ] = useState({ x: -185, y: -90 });
    const [ initialPosition, setInitialPosition ] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        setDragging(true);
        setInitialPosition({
            x: e.clientX,
            y: e.clientY,
        });
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
        requestAnimationFrame(() => {
            e.preventDefault(); // Prevent text selection during drag
            setOffset({x: offset.x + e.clientX - initialPosition.x,
                y: offset.y + e.clientY - initialPosition.y
            });
            // Update the position of the draggable element
            //e.target.style.transform = `translate(${x}px, ${y}px)`;
        });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            document.addEventListener('touchmove', handleMouseMove);
            document.addEventListener('touchend', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDragging]);


    return (
        <div className={`draggable cant-do-warning${largeScreen? '' : '-small'} ${(isDragging && largeScreen)? "dragging" : ""}`}
        onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}
        style=
            {{ transform: largeScreen ? `translate(${offset.x}px, ${offset.y}px)` : 'translate(0px, -50%)' }}
          
        //style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
            <p className={`warning${largeScreen? '' : '-small'}`}>{canMakeChanges.message}</p>
            <button className={`warning-ok${largeScreen? '' : '-small'}`} onClick={() => requestMakeChanges({initialRecord: canMakeChanges.initial, proposedChange: canMakeChanges.record, instance: instance, setCanMakeChanges: setCanMakeChanges})}>Send</button>
            <button className={`warning-ok${largeScreen? '' : '-small'}`} onClick={() => setCanMakeChanges('')}>Cancel</button>
        </div>
    );
}