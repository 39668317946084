import { useState, useEffect } from 'react';
import { putCosmosDBRecord, sendDeleteSignal, getCosmosDBData } from "./fetchData";
import { useMsal } from '@azure/msal-react';


export default function DeleteWarning({largeScreen, deleteRequested, setDeleteRequested, setCanMakeChanges, db, setDataCosmosDB, setTrigger}) {
    const { instance } = useMsal();

    const [ isDragging, setDragging ] = useState(false);
    const [ offset, setOffset ] = useState({ x: -170, y: -90 });
    const [ initialPosition, setInitialPosition ] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        setDragging(true);
        setInitialPosition({
            x: e.clientX,
            y: e.clientY,
        });
    };

    async function refresh() {
        await getCosmosDBData({ instance: instance, setDataCosmosDB: setDataCosmosDB });
        setTrigger(Math.random());
    }

    const handleMouseMove = (e) => {
        if (isDragging) {
        requestAnimationFrame(() => {
            e.preventDefault(); // Prevent text selection during drag
            setOffset({x: offset.x + e.clientX - initialPosition.x,
                y: offset.y + e.clientY - initialPosition.y
            });
            // Update the position of the draggable element
            //e.target.style.transform = `translate(${x}px, ${y}px)`;
        });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            document.addEventListener('touchmove', handleMouseMove);
            document.addEventListener('touchend', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDragging]);

    async function removeFromToDataBase() {
        await putCosmosDBRecord({ setCanMakeChanges: setCanMakeChanges, instance: instance, record: deleteRequested.recordLastValid, setInitialRecord: deleteRequested.setInitialRecord, method: 'DELETE' });
        db = db.filter(obj => obj.id !== deleteRequested.recordLastValid.id);
        setTimeout(function () {
            sendDeleteSignal({instance: instance, id: deleteRequested.recordLastValid.id, setCanMakeChanges: setCanMakeChanges, setDeleteRequested: setDeleteRequested});
            refresh();
          }, 500);
    }

    return (
        <div className={`draggable delete-warning${largeScreen? '' : '-small'} ${(isDragging && largeScreen)? "dragging" : ""}`}
        onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}
        style=
            {{ transform: largeScreen ? `translate(${offset.x}px, ${offset.y}px)` : 'translate(0px, -50%)' }}
          
        //style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
            <p className={`warning${largeScreen? '' : '-small'}`}>Are you sure that you want to completely delete this record?</p>
            <button className={`warning-delete-ok${largeScreen? '' : '-small'}`} onClick={removeFromToDataBase}>Yes</button>
            <button className={`warning-delete-cancel${largeScreen? '' : '-small'}`} onClick={() => setDeleteRequested({ recordLastValid: null, setInitialRecord: null })}>Cancel</button>
        </div>
    );
}