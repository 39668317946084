import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { msalConfig } from './authConfig';
//import reportWebVitals from './reportWebVitals';

export const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(  
  //<React.StrictMode>
    <BrowserRouter>
        <App msalInstance={msalInstance} />
    </BrowserRouter>
  //</React.StrictMode>
);

//      
//      
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
