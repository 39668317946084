export const msalConfig = {
    auth: {
      clientId: '3944170d-21e3-4a96-92c4-15d2055c38b5',
      authority: 'https://login.microsoftonline.com/f0eb4ff0-fa4b-42e9-8a60-c7fd4edd9674',
      redirectUri: 'https://accounts.ysoft.pro/blank.html',
      
    },
    cache: {
      cacheLocation: 'sessionStorage',
    },
  };

export const loginRequestAll = {
    scopes: [ 'https://storage.azure.com/.default', 'https://storage.azure.com/.default', 'https://cosmos.azure.com/user_impersonation', 'api://041e0a00-36ce-4ead-962e-5efdfb1ea062/user_impersonation' ]
  }
  
export const loginRequest = {
    scopes: [ 'https://storage.azure.com/.default' ]
  }

export const cosmosRequest = {
    scopes: [ 'https://cosmos.azure.com/user_impersonation' ]
  }

export const functionRequest = {
    scopes: [ 'api://041e0a00-36ce-4ead-962e-5efdfb1ea062/user_impersonation' ]
  }

  export const sendChangeRequest = {
    scopes: [ 'https://servicebus.azure.net//user_impersonation' ]
  }
