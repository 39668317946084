import { patternB, patternC, patternR, generalFiles } from "./fileFilterConfig";
import { sortBlobs } from "./sortBlobNames";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequestAll, loginRequest, cosmosRequest, functionRequest, sendChangeRequest } from './authConfig';

const container_name = 'invoices';
var urlBlob = null;
const parser = new DOMParser();

export var data = [];
export var data2 = [];
export var filesTypes = { 
    generalFiles: [],
    rfiles: [],
    cfiles: [],
    bfiles: [],
};

export async function sendDeleteSignal({instance, id, setCanMakeChanges, setDeleteRequested}) {
    const url = `https://removerecord.azurewebsites.net/api/httptriggerremoverecord?todelete=${id}`;
    await instance.acquireTokenSilent(functionRequest).then(tokenResponse => {
        try {
            fetch(url, {
                method: 'DELETE', 
                headers: {
                    'Authorization': 'Bearer ' + tokenResponse.accessToken,
                    'x-functions-key': 'gflG3DNzWfbL3Myuda5vXwZX10RzDiuBM0WmpPJz2gqVAzFuDe-OmQ==',
                }})
                .then((response) => {
                    console.log(`${response.status} is the status deleting from transactions`);
                    if (response.status === 200) {
                        console.log(`${id} successfully deleted from transactions`);
                    } else if (response.status === 404) {
                        console.error(`${id} does not exist in transactions`);
                    } else if (response.status === 400) {
                        console.error(`Formating error deleting ${id} from transactions`);
                    } else if (response.status === 403) {
                        console.error(`No authorization for deleting ${id} from transactions`);
                        setCanMakeChanges('It seems that you are currently not authorized to make these changes');
                    } else {
                        console.error(`Some other error deleting ${id} from transactions`);
                    }
                    setDeleteRequested({ recordLastValid: null, setInitialRecord: null });
                })
                .catch((error) => {
                    console.error(`Something went wrong deleting ${id} from transactions`);
                    setDeleteRequested({ recordLastValid: null, setInitialRecord: null });
                });
        } catch (error) {
            console.log(`Inside catch after sendDeleteSignal: ${error}`);
            setDeleteRequested({ recordLastValid: null, setInitialRecord: null });
        }});
}

export var CofA = null;
const databaseId = 'bookkeeping';
const containerId = 'bookkeeping';
const urlAllDocs = `https://ysoft-northeurope.documents.azure.com:443/dbs/${databaseId}/colls/${containerId}/docs/`;

export async function putCosmosDBRecord(args) {
    await args.instance.acquireTokenSilent(cosmosRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return args.instance.acquireTokenRedirect(cosmosRequest);
        } else {
          console.log(`Some other error: ${error}`);
        }
      }).then(tokenResponse => {
        try {
            fetch(urlAllDocs + args.record.id, {
                method: args.method,
                headers: {
                    'Authorization': `type=aad&ver=2.0&sig=${(tokenResponse.accessToken)}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Content-Length': JSON.stringify(args.record).length,
                    'x-ms-version': '2020-07-15',
                    'X-Ms-Documentdb-Partitionkey': `[${args.record.year},${args.record.month},"${args.record.id}"]`
                },
                body: JSON.stringify(args.record)
            })
            .then((response) => {
                if (response.ok) {
                    console.log("Record posted to Cosmos DB successfully.");
                    args.setInitialRecord(args.record);
                } else if (response.status === 403) {
                    console.error(`Not authorized for posting record to Cosmos DB: ${response.status} - ${response.statusText}`);
                    args.setCanMakeChanges({message: 'It seems that you are currently not authorized to make these changes directly. Do you want to send them for approval?', initial: args.initial, record: args.record});
                } else console.error(`Error posting record to Cosmos DB: ${response.status} - ${response.statusText}`);
            })
            .catch((error) => {
                console.error(`Error posting record to Cosmos DB: ${error.message}`);
            });
        } catch (error) {
            console.log(`Inside catch after CosmosClient: ${error}`);
        }
    });
}

export async function readChangeRequests(args) {
    //await args.instance.loginPopup(sendChangeRequest);
    console.log(`Inside readChangeRequests`);
    const getChangeRequestsURL = 'https://ydouble.servicebus.windows.net/record_change_requests/messages/head';
    await args.instance.acquireTokenSilent(sendChangeRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return args.instance.acquireTokenRedirect(sendChangeRequest);
        } else {
          console.log(`Some other error: ${error}`);
        }
      }).then(tokenResponse => {
        try {
            fetch(getChangeRequestsURL, {
                method: 'POST',
                headers: {
                    //'Authorization': `type=aad&ver=2.0&sig=${(tokenResponse.accessToken)}`,
                    'Authorization': `Bearer ${(tokenResponse.accessToken)}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-ms-version': '2020-07-15',
                    'maxMessages': '20',
                    //'timeout': '60',
                    //'api-version': '2021-05'
                },
                crossorigin: true,
                //mode: "no-cors",
                //body: JSON.stringify({initialRecord: args.initialRecord, proposedChange: args.proposedChange})
            })
            .then((response) => {
                if (!response.ok) {                  
                    if (response.status === 403) {
                        //args.setCanMakeChanges('It seems that you are currently not authorized to send change requests for approval');
                        throw new Error(`Not authorized for getting record change requests from Bus: ${response.status} - ${response.statusText}`);
                    } else throw new Error(`Error fetching record change requests from Bus: ${response.status} - ${response.statusText}`);
                }
                console.log("Record change request received from Bus successfully.");
                args.setIdLocked(response.headers.get('Location'));
                return response.json();
            })
            .catch((error) => {
                console.error(`Error tetching record change requests from Bus: ${error.message}`);
            })
            .then(data => {
                console.log(data);
                if (typeof data !== 'undefined') args.setRecords([data]);
            });
        } catch (error) {
            console.log(`Inside catch after Bus readChangeRequests scope attempt: ${error}`);
        }
    });
}

export async function rejectMakeChanges({instance, Location}) {
    //await args.instance.loginPopup(sendChangeRequest);
    console.log(`Inside rejectMakeChanges`);
    const rejectChangeRequestURL = Location;
    await instance.acquireTokenSilent(sendChangeRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(sendChangeRequest);
        } else {
          console.log(`Some other error: ${error}`);
        }
      }).then(tokenResponse => {
        try {
            fetch(rejectChangeRequestURL, {
                method: 'DELETE',
                headers: {
                    //'Authorization': `type=aad&ver=2.0&sig=${(tokenResponse.accessToken)}`,
                    'Authorization': `Bearer ${(tokenResponse.accessToken)}`,
                    //'Accept': 'application/json',
                    //'Content-Type': 'application/json',
                    //'x-ms-version': '2020-07-15',
                },
                crossorigin: true,
                //mode: "no-cors",
            })
            .then((response) => {
                if (response.ok) {
                    console.log("Record change request rejected in Bus successfully.");
                } else if (response.status === 403) {
                    console.error(`Not authorized for sending record change rekect requests to Bus: ${response.status} - ${response.statusText}`);
                } else console.error(`Error sending record change request reject to Bus: ${response.status} - ${response.statusText}`);
            })
            .catch((error) => {
                console.error(`Error sending record change request reject to Bus: ${error.message}`);
            });
        } catch (error) {
            console.log(`Inside catch after Bus sendChangeRequest scope attempt in rejectMakeChanges: ${error}`);
        }
    });
}

export async function requestMakeChanges(args) {
    //await args.instance.loginPopup(sendChangeRequest);
    console.log(`Inside requestMakeChanges`);
    const sendChangeRequestURL = 'https://ydouble.servicebus.windows.net/record_change_requests/messages';
    await args.instance.acquireTokenSilent(sendChangeRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return args.instance.acquireTokenRedirect(sendChangeRequest);
        } else {
          console.log(`Some other error: ${error}`);
        }
      }).then(tokenResponse => {
        try {
            fetch(sendChangeRequestURL, {
                method: 'POST',
                headers: {
                    //'Authorization': `type=aad&ver=2.0&sig=${(tokenResponse.accessToken)}`,
                    'Authorization': `Bearer ${(tokenResponse.accessToken)}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Content-Length': JSON.stringify({initialRecord: args.initialRecord, proposedChange: args.proposedChange}).length,
                    'x-ms-version': '2020-07-15',
                },
                crossorigin: true,
                //mode: "no-cors",
                body: JSON.stringify({initialRecord: args.initialRecord, proposedChange: args.proposedChange})
            })
            .then((response) => {
                if (response.ok) {
                    console.log("Record change request sent to Bus successfully.");
                    args.setCanMakeChanges('');
                } else if (response.status === 403) {
                    console.error(`Not authorized for sending record change requests to Bus: ${response.status} - ${response.statusText}`);
                    args.setCanMakeChanges('It seems that you are currently not authorized to send change requests for approval');
                } else console.error(`Error sending record change request to Bus: ${response.status} - ${response.statusText}`);
            })
            .catch((error) => {
                console.error(`Error sending record change request to Bus: ${error.message}`);
            });
        } catch (error) {
            console.log(`Inside catch after Bus sendChangeRequest scope attempt: ${error}`);
        }
    });
}

function compareDates (a, b) {
    if (a.year !== b.year) return a.year - b.year;    
    if (a.month !== b.month) return a.month - b.month;
    return a.day - b.day;
};

export async function getCosmosDBData(args) {
    await args.instance.acquireTokenSilent(cosmosRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return args.instance.acquireTokenRedirect(cosmosRequest);
        } else {
          console.log(`Some other error: ${error}`);
        }
      }).then(tokenResponse => {
        try {
            fetch(urlAllDocs, {
                method: 'GET',
                headers: {
                    'Authorization': `type=aad&ver=2.0&sig=${(tokenResponse.accessToken)}`,
                    'Accept': 'application/json',
                    'x-ms-version': '2018-12-31',
                },
            })
            .then((response) => response.json())
            .then((data) => {
                args.setDataCosmosDB(data['Documents'].sort(compareDates));
                //data['Documents'].forEach((record) => console.log(record)); 
            })
            .catch((error) => {
                console.error('Error inside getCosmosDocs:', error);
            });
        } catch (error) {
            console.log(`Inside catch after CosmosClient: ${error}`);
        }
    });
}

export function getData(args) {
    return new Promise((resolve, reject) => {
        const container_header = '?comp=list&restype=container&_=' + Math.floor(Math.random() * 10000000000000).toString();
        const CofA_header = '?_=' + Math.floor(Math.random() * 10000000000000).toString();
        var urlContainer = `https://ysoftdata.blob.core.windows.net/${container_name}${container_header}`;
        var urlContainerCofA = `https://ysoftdata.blob.core.windows.net/${container_name}/chart_of_accounts.json${CofA_header}`;
        const req = new XMLHttpRequest();
        
        req.onreadystatechange = () => {
            if (req.readyState === 4 && req.status === 200) {
                args.setNotAuthorized(false);
                console.log("Response with data received: " + req.status);
                var xml = req.responseXML;
                if (xml) {
                    var blobs = xml.getElementsByTagName("Blob");

                    filesTypes.rfiles.length = 0;
                    filesTypes.cfiles.length = 0;
                    filesTypes.bfiles.length = 0;
                    filesTypes.generalFiles.length = 0;
                    
                    Array.from(blobs).forEach(blob => {
                        const child = blob.childNodes;
                        const fileName = child[0].textContent;
                        const fileSize = Math.round(parseInt(child[1].getElementsByTagName('Content-Length')[0].textContent) / 1024.0).toString();
                        if (patternR.test(fileName)) filesTypes.rfiles.push({name: fileName, size: fileSize});
                        else if (patternC.test(fileName)) filesTypes.cfiles.push({name: fileName, size: fileSize});
                        else if (patternB.test(fileName)) filesTypes.bfiles.push({name: fileName, size: fileSize});
                        else if (generalFiles.includes(fileName)) filesTypes.generalFiles.push({name: fileName, size: fileSize});
                    });
                    filesTypes.rfiles.sort(sortBlobs);
                    filesTypes.cfiles.sort(sortBlobs);
                    filesTypes.bfiles.sort(sortBlobs);
                } else {
                    console.error("Failed to parse XML.");
                }
                resolve({ isLoginSuccessful: true, IsNotAuthorized: false, tokens: args.tokens });
            } else if (req.status === 403) {
                console.log(`Inside status 403!!!`);
                const xmlDoc = parser.parseFromString(req.responseText, "text/xml");
                if (xmlDoc) {      
                    if (xmlDoc.getElementsByTagName("Message")[0]) {
                        var errorMessage = xmlDoc.getElementsByTagName("Message")[0].textContent;
                        if (errorMessage) console.log("Custom responce text", xmlDoc.getElementsByTagName("Message")[0]);
                        if (errorMessage && errorMessage.includes("This request is not authorized to perform this operation using this permission.")) {
                            console.log(`Inside the end of non-authorization!!!`);
                            args.setNotAuthorized(true);
                        }
                    }
                }
                resolve({ isLoginSuccessful: true, IsNotAuthorized: true, tokens: args.tokens });
            } else if (req.readyState === 4) {
                args.setNotAuthorized(null);
                console.error("Failed to retrieve data. Status code: " + req.status);
                resolve({ isLoginSuccessful: true, IsNotAuthorized: true, tokens: args.tokens });
            }
        };

        req.open('GET', urlContainer, true);
        req.setRequestHeader('authorization', 'Bearer ' + args.tokens.accessToken);
        req.setRequestHeader('x-ms-version', '2023-08-03');
        req.setRequestHeader('Accept', 'application/xml');
        req.send(null);

        fetch(urlContainerCofA, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${args.tokens.accessToken}`,
              'x-ms-version': '2023-08-03',
              'Accept': 'application/json'
            },
          })
            .then(response => {
                if (response.status === 403) { 
                    args.setNotAuthorized(true);
                    console.error('Access is forbidden (403)');
                } else if (!response.ok) {
                    console.error(`Other error accessing the chart of accounts json. Network response was ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                CofA = data;
                //console.log(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    });
}


export async function downloadFileFromAzureContainer(fileName, msalInstance) {
    urlBlob = `https://ysoftdata.blob.core.windows.net/${container_name}/${fileName}`;

    msalInstance.acquireTokenSilent(loginRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenRedirect(loginRequest);
        } else {
          console.log(`Some other error: ${error}`);
        }
      }).then(tokenResponse => {
            fetch(urlBlob, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${tokenResponse.accessToken}`,
                    "x-ms-version": "2023-08-03",
                    "Accept": "application/octet-stream"
                }
            })
            .then((response) => {
                if (response.status === 200) return response.blob();
                else throw new Error("File request failed with status: " + response.status);
            })
            .then((blob) => {
                const localBlobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = 'none';
                a.href = localBlobUrl;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(localBlobUrl);
            })
            .catch((error) => {
                console.error(`Error downloading file ${fileName}:` , error);
            });
      });   
}