import { filesTypes, downloadFileFromAzureContainer } from "./fetchData";
import { useMsal } from '@azure/msal-react';

export default function BankConfirmations() {
    const { instance } = useMsal();

    return (
        <div className="tab-content" id="BFiles_tab">
            <h2>Bank payments and transfers</h2>
            <table id="BFiles_table">
                <thead>
                    <tr>
                        <th>File name</th>
                        <th>File size (kB)</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filesTypes.bfiles.map(item => (
                    <tr key={item.name}>
                        <td>{item.name}</td>
                        <td>{item.size}</td>
                        <td>
                            <button onClick={function() {
                                downloadFileFromAzureContainer(item.name, instance);
                            }}>Download</button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}