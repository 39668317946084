import exitImage from './exit.png';
import refreshImage from './refresh.png';
import backImage from './back.png';
import './Bookkeeping.css';
import { useState, useEffect } from 'react';
import { Outlet, NavLink } from "react-router-dom";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { BsSave } from "react-icons/bs";
import { getData, filesTypes, downloadFileFromAzureContainer } from "./fetchData";

export default function Bookkeeping(props) {
    const ifActive = ({isActive}) => isActive ? "active-tab" : "tab";
    const { instance } = useMsal();
    const account = useAccount();
    const [ largeScreen, setLargeScreen ] = useState(window.matchMedia('(min-device-width: 769px)').matches || window.screen.width > 768);

    async function refresh() {
        await instance.acquireTokenSilent(loginRequest).then(tokenResponse => {
            try {
                    getData({ tokens: tokenResponse, setNotAuthorized: props.setNotAuthorized });
                    console.log(account.name);
                } catch (error) {
                    console.log(`Inside catch after getData`);
                }
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    console.log(`InteractionRequiredAuthError: ${error}`);
                } else {
                    console.log(`Some other error: ${error}`);
                }
            });
    }

    useEffect(() => {
        refresh();
        props.setToDeleteHeaderFooter(false);
        if (!largeScreen) props.setMinifyFooter(true);
        else props.setMinifyFooter(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLargeScreen(window.matchMedia('(min-device-width: 769px)').matches || window.screen.width > 768);
    });

    return (
        <>
            <AuthenticatedTemplate>
                {props.isNotAuthorized && 
                <>
                    <h2 className="no-authorization">You successfully logged in but aren't authorized to access the data</h2>
                    <NavLink to="/login">
                    <button className="loginButton">
                        <em>L</em>og-out page
                    </button>
                </NavLink>
                </>
                }
                {props.isNotAuthorized === false && (largeScreen ?
                <>
                    <div className="tabs-container">
                        <div className="tabs">
                            <NavLink className={ifActive} to="general">Bookkeeping</NavLink>
                            <NavLink className={ifActive} to="receipts">R files</NavLink>
                            <NavLink className={ifActive} to="invoices">C files</NavLink>
                            <NavLink className={ifActive} to="bank-confirmations">B files</NavLink>
                            <NavLink to="/"><button className="exit"><img className="exitImg" src={backImage} alt="Back" border="0" width="38" height="38"/></button></NavLink>
                            <NavLink to="/bookkeeping/general"><button className="exit" onClick={refresh}><img className="exitImg" src={refreshImage} alt="Refresh" border="0" width="38" height="38"/></button></NavLink>
                            <NavLink to="/login"><button className="exit" onClick={props.signOut}><img className="exitImg" src={exitImage} alt="Exit" border="0" width="38" height="38"/></button></NavLink>
                        </div>
                    </div>
                    <Outlet />
                </> :
                <>
                <div className="tabs">
                    <div className="upper-tabs">
                        <NavLink to="/"><button className="exit"><img className="exitImg" src={backImage} alt="Back" border="0" width="38" height="38"/></button></NavLink>
                        <NavLink to="/bookkeeping/general"><button className="exit" onClick={refresh}><img className="exitImg" src={refreshImage} alt="Refresh" border="0" width="38" height="38"/></button></NavLink>
                        <NavLink to="/login"><button className="exit" onClick={props.signOut}><img className="exitImg" src={exitImage} alt="Exit" border="0" width="38" height="38"/></button></NavLink>
                    </div>
                    <div className="files-panel-outer-container">
                        <h3>General bookkeeping files</h3>
                        <div className="files-panel-container">
                            <div className="files-panel">
                                {filesTypes.generalFiles.map(item => (
                                    <div className="file-box" key={item.name}>
                                        <h4 className="file-name">{item.name.split('.')[0]}<br/>.{item.name.split('.')[1]}</h4>
                                        <p className="file-size">{item.size} kB</p>
                                        <BsSave onClick={function() {
                                            downloadFileFromAzureContainer(item.name, instance);
                                        }}/>
                                    </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="files-panel-outer-container">
                        <h3>Receipts</h3>
                        <div className="files-panel-container">
                            <div className="files-panel">
                                {filesTypes.rfiles.map(item => (
                                    <div className="file-box" key={item.name}>
                                        <h4 className="file-name">{item.name}</h4>
                                        <p className="file-size">{item.size} kB</p>
                                        <BsSave onClick={function() {
                                            downloadFileFromAzureContainer(item.name, instance);
                                        }}/>
                                    </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="files-panel-outer-container">
                        <h3>Invoices</h3>
                        <div className="files-panel-container">
                            <div className="files-panel">
                                {filesTypes.cfiles.map(item => (
                                    <div className="file-box" key={item.name}>
                                        <h4 className="file-name">{item.name}</h4>
                                        <p className="file-size">{item.size} kB</p>
                                        <BsSave onClick={function() {
                                            downloadFileFromAzureContainer(item.name, instance);
                                        }}/>
                                    </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="files-panel-outer-container">
                        <h3>Bank payments and transfers</h3>
                        <div className="files-panel-container">
                            <div className="files-panel">
                                {filesTypes.bfiles.map(item => (
                                    <div className="file-box" key={item.name}>
                                        <h4 className="file-name">{item.name}</h4>
                                        <p className="file-size">{item.size} kB</p>
                                        <BsSave onClick={function() {
                                            downloadFileFromAzureContainer(item.name, instance);
                                        }}/>
                                    </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h2 className="no-authorization">You are not authorized to be on this page<br/><br/>Please log in</h2>
                <NavLink to="/login">
                    <button className="loginButton">
                        <em>L</em>og-in page
                    </button>
                </NavLink>
            </UnauthenticatedTemplate>
        </>
    );
}