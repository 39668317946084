import './Login.css';
import LoginSuccess from "./LoginSuccess";
import { useEffect } from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { getData, getCosmosDBData } from "./fetchData";
import { Link } from "react-router-dom";

export default function Login(props) {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const account = useAccount();

    useEffect(() => { 
        props.setToDeleteHeaderFooter(false);
        props.setMinifyFooter(false);
    },[]);
/*
    useEffect(() => {
        const main = document.querySelector('main');
        const main_logo_container = document.querySelector('#main_logo_container');
        const footer = document.querySelector('footer');
        const mediaQueryLargeScreen = window.matchMedia('(min-device-width: 769px)');
        if (mediaQueryLargeScreen) {
            main.style.width = '640px';
            main.style.minWidth = '640px';
        } else {
            main.style.width = '100%';
            main.style.minWidth = '100%';
        }
        main_logo_container.style.display = 'flex';
        footer.style.display = 'block';
    }, []);*/

    async function refresh() {
        await instance.acquireTokenSilent(loginRequest).then(tokenResponse => {
            try {
                    getData({ tokens: tokenResponse, setNotAuthorized: props.setNotAuthorized });
                } catch (error) {
                    console.error(`Inside catch error after getData in refresh with the error ${error}`);
                }
        }).catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
                console.error(`InteractionRequiredAuthError: ${error}`);
            } else {
                console.error(`Some other error in acquiring token in refresh: ${error}`);
            }
        });
        await getCosmosDBData({ instance: instance, setDataCosmosDB: props.setDataCosmosDB });
    }

    useEffect(() => {
        if (isAuthenticated) refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <div>
            <AuthenticatedTemplate>
                <p className="greeting"><em>W</em>elcome {account?.name.split(" ")[0]}</p>
                {props.isNotAuthorized && <h2 className="no-authorization">You successfully logged in but aren't authorized to access the data</h2>}
                {props.isNotAuthorized === false && <LoginSuccess />}
                {props.dataCosmosDB && 
                    <div>
                        <Link to="/atomicrecords">
                            <button className="loginButton">
                                <em>A</em>tomic records
                            </button>
                        </Link>
                    </div>}
                {props.dataCosmosDB && props.serviceBus && 
                    <div>
                        <Link to="/changedrecords">
                            <button className="loginButton">
                                <em>C</em>hange requests
                            </button>
                        </Link>
                    </div>}
                <button className="loginButton" onClick={props.signOut}>
                    <em>L</em>og out
                </button>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <button className="loginButton" onClick={props.signIn}>
                    <em>L</em>og in
                </button>
            </UnauthenticatedTemplate>
        </div>
    );
};