import './App.css';
import Login from "./Login";
import Bookkeeping from "./Bookkeeping";
import General from "./General";
import Receipts from "./Receipts";
import Invoices from "./Invoices";
import BankConfirmations from "./BankConfirmations";
import NotFound from "./NotFound";
import AtomicRecords from "./AtomicRecords";
import ChangedRecords from "./ChangedRecords";
import { Route, Routes } from "react-router-dom";
import { loginRequest } from './authConfig';
import { getData, getCosmosDBData } from "./fetchData";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal, MsalProvider } from '@azure/msal-react';
import { filesTypes } from "./fetchData";
import { useState } from 'react';
import logoImg from './logo.png';


async function authentication(args) {
  switch (args.action) {
    case "signin":
      await args.msalInstance.loginPopup(loginRequest);
      await args.msalInstance.acquireTokenSilent(loginRequest).then(tokenResponse => {
        try {
          getData({ tokens: tokenResponse, setNotAuthorized: args.setNotAuthorized });
          console.log(args.msalInstance.getAllAccounts()[0].name);
        } catch (error) {
          console.log(`Inside catch after getData`);
        }
      }).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          console.log(`InteractionRequiredAuthError: ${error}`);
        } else {
          console.log(`Some other error: ${error}`);
        }
      });
      await getCosmosDBData({ instance: args.msalInstance, setDataCosmosDB: args.setDataCosmosDB });
      break;
    case "signout": {
      const accounts = await args.msalInstance.getAllAccounts();
      for (let user of accounts) {
          await console.log(`Signing out ${user.name}`);
          await args.msalInstance.logoutPopup({account: user});
          await args.msalInstance.clearCache();
      }
      break;
    }
    default:
      return null;
  }
}

function AppContent(props) {
  const { instance } = useMsal();
  const [ notAuthorized, setNotAuthorized ] = useState();
  const [ dataCosmosDB, setDataCosmosDB ] = useState();
  const [ serviceBus, setServiceBus ] = useState(true);
  const [ toDeleteHeaderFooter, setToDeleteHeaderFooter ] = useState(false);
  const [ minifyFooter, setMinifyFooter ] = useState(false);
  
  async function signIn() {
    try {
      await authentication({action: "signin", msalInstance: instance, setNotAuthorized: setNotAuthorized, setDataCosmosDB: setDataCosmosDB });
    } catch (error) {
      console.error(`The sign-in attempt resulted in error: ${error}`);
    }
  }

  async function signOut() {
    try {
      await authentication({action: "signout", msalInstance: instance });
      filesTypes.rfiles.length = 0;
      filesTypes.cfiles.length = 0;
      filesTypes.bfiles.length = 0;
      filesTypes.generalFiles.length = 0;
      setNotAuthorized(null);
    } catch (error) {
      console.error(`The sign-out attempt resulted in error: ${error}`);
    }
  }

  return (
    <div className="App">
      <header className={toDeleteHeaderFooter ? "removeHeaderFooter" : ""} id={toDeleteHeaderFooter ? "" : "main_logo_container"}>
        <img id="main_logo" src={logoImg} width="300" height="300"/>
      </header>
      <main>
        <Routes>
          <Route path="index.html" element={<Login setDataCosmosDB={setDataCosmosDB} setMinifyFooter={setMinifyFooter} setToDeleteHeaderFooter={setToDeleteHeaderFooter} dataCosmosDB={dataCosmosDB} setNotAuthorized={setNotAuthorized} isNotAuthorized={notAuthorized} signIn={signIn} signOut={signOut} serviceBus={serviceBus}/>}/>
          <Route path="login" element={<Login setDataCosmosDB={setDataCosmosDB} setMinifyFooter={setMinifyFooter} setToDeleteHeaderFooter={setToDeleteHeaderFooter} dataCosmosDB={dataCosmosDB} setNotAuthorized={setNotAuthorized} isNotAuthorized={notAuthorized} signIn={signIn} signOut={signOut} serviceBus={serviceBus}/>}/>
          <Route path="/" element={<Login setDataCosmosDB={setDataCosmosDB} setMinifyFooter={setMinifyFooter} setToDeleteHeaderFooter={setToDeleteHeaderFooter} dataCosmosDB={dataCosmosDB} setNotAuthorized={setNotAuthorized} isNotAuthorized={notAuthorized} signIn={signIn} signOut={signOut} serviceBus={serviceBus}/>}/>
          <Route path="*" element={<NotFound />}/>
          <Route path="atomicrecords" element={<AtomicRecords setMinifyFooter={setMinifyFooter} setToDeleteHeaderFooter={setToDeleteHeaderFooter} setDataCosmosDB={setDataCosmosDB} dataCosmosDB={dataCosmosDB} signOut={signOut} />}/>
          <Route path="changedrecords" element={<ChangedRecords setMinifyFooter={setMinifyFooter} setToDeleteHeaderFooter={setToDeleteHeaderFooter} setDataCosmosDB={setDataCosmosDB} dataCosmosDB={dataCosmosDB} signOut={signOut} serviceBus={serviceBus} />}/>
          <Route path="bookkeeping" element={<Bookkeeping setMinifyFooter={setMinifyFooter} setToDeleteHeaderFooter={setToDeleteHeaderFooter} setNotAuthorized={setNotAuthorized} isNotAuthorized={notAuthorized} signOut={signOut} />}>
            <Route path="general" element={<General />}/>
            <Route path="receipts" element={<Receipts />}/>
            <Route path="invoices" element={<Invoices />}/>
            <Route path="bank-confirmations" element={<BankConfirmations />}/>
          </Route>
        </Routes>
      </main>
      <footer className={toDeleteHeaderFooter ? "removeHeaderFooter" : minifyFooter ? "minifyFooter" : ""}>
        <p>&copy; 2023 Y SOFT LLC</p>
        <p><a href="https://ysoft.pro">ysoft.pro</a></p>
        <p className="widestText">THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.</p>
        <br/><br/>
      </footer>
    </div>
  );
}



export default function App({msalInstance}) {
  return (
    <MsalProvider instance={msalInstance}>
      <AppContent />
    </MsalProvider>
  );
}
